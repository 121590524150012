import device from './device'
import drinks from './drinks'

export default {
  path: 'equipment',
  name: 'equipment',
  meta: {
    title:'设备',
    redirect: true,
  },
  component: () => import('@/views/redirect'),
  children: [
    {
      path: 'product',
      name: 'equipment.product',
      component: () => import('@/views/Equipment/product'),
      meta: {
        title:'产品库',
        bgClass: 'color-bg',
      }
    },
    {...device},
    {...drinks},
  ]
}
