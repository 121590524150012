export default {
  path: 'device',
  name: 'equipment.device',
  component: () => import('@/views/redirect'),
  redirect: {name: 'equipment.device.index'},
  meta: {
    title: '设备档案',
    redirect: true,
  },
  children: [
    {
      path: 'index',
      name: 'equipment.device.index',
      component: () => import('@/views/Equipment/Device/index'),
    },
    {
      path: 'detail/:uuid',
      name: 'equipment.device.detail',
      component: () => import('@/views/Equipment/Device/detail'),
      meta: {
        title: '编辑设备',
        bgClass: 'color-bg',
        hidden: true,
      }
    },
    {
      path: 'drink/:deviceUUID/:uuid',
      name: 'equipment.device.drink',
      component: () => import('@/views/Equipment/Device/drink'),
      meta: {
        title: '设备饮品',
        bgClass: 'color-bg',
        hidden: true,
      }
    },
  ]
}
