<template>
  <el-row class="step-container">
    <el-col :span="18">
      <div class="container">
        <el-page-header @back="$router.back()" :content="name"></el-page-header>
        <slot name="back">
        </slot>
      </div>
      <p class="remark" v-if="remark">{{remark}}</p>
      <div class="step-container-btn">
        <slot name="step" >
          <el-steps :active="current" :simple="true" finish-status="success">
            <el-step v-for="item in steps" :key="item.title" :title="item.title"/>
          </el-steps>
        </slot>
      </div>

    </el-col>

    <el-col :span="6" class="tip-container">
      <img class="tip" src="../../assets/img/tip.png">
    </el-col>

  </el-row>
</template>

<script>
  export default {
    name: 'step-container',
    components: {},
    props: {
      name: {
        type: String,
        default: ''
      },
      remark: {
        type: String,
        default: ''
      },
      current: {
        type: Number,
        default: 0
      },
      steps: {
        type: Array,
        default: function () {
          return []
        }
      }
    },
    mounted: function () {
    },
    data: function () {
      return {}
    },
    methods: {},
  }
</script>

<style lang="scss" scoped>
  .step-container {
    padding: $middle-space $container-padding;
    padding-bottom: 0;
    background-color: $color-white;

    ::v-deep .el-steps--simple {
      padding: 0 %8;
      border-radius: 0;
      background: white;

      .el-step__title {
        @include font-medium-s();
      }

      .el-step__arrow:before {
        display: none;
      }

      .el-step__arrow:after {
        width: 80%;
        transform: none;
        height: 1px;
      }
    }

    .tip-container {
      text-align: right;

      .tip {
        width: 132px;
      }
    }


    .remark {
      margin: $small-space 0;
      @include font-little();
      color: $gray-7;
    }

    .container {
      display: flex;
      justify-items: center;
      align-items: flex-end;

      &-name {
        @include font-large-s();
        @include page-title();
      }

    }

    &-btn{
      .el-button, .el-button--text {
        color: $color-base !important;
        padding-left: 0;
      }

      .el-button:hover {
        color: $theme-color;
        font-weight: 500;
      }

      &-back {
        padding-left: 4px;
        padding-top: 4px;
      }
    }

  }
</style>
