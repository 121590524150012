<template>
  <div class="empty">
    <img :src="Empty"/>
    <slot>
      <p>未找到搜索信息，用其他条件搜索试试</p>
    </slot>

  </div>
</template>

<script>
  import Empty from '@/assets/img/empty.png'

  export default {
    name: 'empty',
    components: {},
    mounted: function () {
    },
    data: function () {
      return {
        Empty: Empty,
      }
    },
    methods: {},
  }
</script>

<style lang="scss" scoped>
  .empty {
    margin: $middle-space 0;
    img {
      max-width: 320px;
      margin-top: $middle-space;
    }

    p {
      color: #666666;
      font-size: 13px;
      cursor: pointer;
    }
  }
</style>
