export default {
  path: 'goods',
  name: 'mkt.goods',
  meta: {
    title: '商品信息',
    redirect: true,
  },
  component: () => import('@/views/redirect'),
  children: [
    {
      path: 'list',
      name: 'mkt.goods.list',
      component: () => import('@/views/Mkt/Goods/index'),
      meta: {
        title: '商品列表',
        bgClass: 'color-bg',
        collapse: true,
      }
    },
    {
      path: 'entity',
      name: 'mkt.goods.entity',
      component: () => import('@/views/Mkt/Goods/publish'),
      meta: {
        title: '商品明细',
        bgClass: 'color-bg',
        hidden: true,
      }
    },
    {
      path: 'sort',
      name: 'mkt.goods.sort',
      component: () => import('@/views/Mkt/Goods/Sort/index'),
      meta: {
        title: '商品分类',
        collapse: true,
      }
    },
  ]
}
