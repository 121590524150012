export default {
  sorts: {
    url: '/v1/mkt/goods/sorts',
    auth: true,
    method: 'GET',
  },

  sortCreate: {
    url: '/v1/mkt/goods/sortCreate',
    auth: true,
    method: 'POST',
  },

  sortUpdate: {
    url: '/v1/mkt/goods/sortUpdate',
    auth: true,
    method: 'POST',
  },

  sortDelete: {
    url: '/v1/mkt/goods/sortDelete',
    auth: true,
    method: 'GET',
  },

  union: {
    url: '/v1/mkt/goods/union',
    auth: true,
    method: 'GET',
  },
  list: {
    url: '/v1/mkt/goods/list',
    auth: true,
    method: 'GET',
  },

  detail: {
    url: '/v1/mkt/goods/detail',
    auth: true,
    method: 'GET',
  },

  create: {
    url: '/v1/mkt/goods/create',
    auth: true,
    method: 'POST',
  },

  update: {
    url: '/v1/mkt/goods/update',
    auth: true,
    method: 'POST',
  },

  delete: {
    url: '/v1/mkt/goods/delete',
    auth: true,
    method: 'GET',
  },
}
