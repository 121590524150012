export default {
  path: 'endpoint/product',
  name: 'system.endpoint.product',
  meta:{
    title: '终端连接'
  },
  component: () => import('@/views/redirect'),
  redirect: {name: 'system.endpoint.product.info'},
  children:[
    {
      path: 'info',
      name: 'system.endpoint.product.info',
      meta:{
        title: '模型管理'
      },
      component: () => import('@/views/System/Endpoint/Product/info'),
    },
    {
      path: 'detail/:uuid',
      name: 'system.endpoint.product.detail',

      component: () => import('@/views/System/Endpoint/Product/detail'),
      meta:{
        title: '模型详情',
        bgClass: 'color-bg',
        hidden: true,
      }
    },
  ]
}
