export default {
  analyze: {
    url: `/v1/equipment/device/analyze`,
    method: 'GET',
    auth: true,
  },

  orgDrinks: {
    url: `/v1/equipment/device/orgDrinks`,
    method: 'GET',
    auth: true,
  },
  union: {
    url: `/v1/equipment/device/union`,
    method: 'GET',
    auth: true,
  },
  list: {
    url: `/v1/equipment/device/list`,
    method: 'GET',
    auth: true,
  },
  detail: {
    url: `/v1/equipment/device/detail`,
    method: 'GET',
    auth: true,
  },
  drinks: {
    url: `/v1/equipment/device/drinks`,
    method: 'GET',
    auth: true,
  },
  authorizeList: {
    url: `/v1/equipment/device/authorizeList`,
    method: 'GET',
    auth: true,
  },
  authorizeCancel: {
    url: `/v1/equipment/device/authorizeCancel`,
    method: 'POST',
    auth: true,
  },
  logUnion: {
    url: `/v1/equipment/device/logUnion`,
    method: 'GET',
    auth: true,
  },
  logs: {
    url: `/v1/equipment/device/logs`,
    method: 'GET',
    auth: true,
  },
  update: {
    url: `/v1/equipment/device/update`,
    method: 'POST',
    auth: true,
  },
  drinkPriceUpdate: {
    url: `/v1/equipment/device/drinkPriceUpdate`,
    method: 'POST',
    auth: true,
  },
  drinkDetail: {
    url: `/v1/equipment/device/drinkDetail`,
    method: 'GET',
    auth: true,
  },
  drinkUpdate: {
    url: `/v1/equipment/device/drinkUpdate`,
    method: 'POST',
    auth: true,
  },
  authorize: {
    url: `/v1/equipment/device/authorize`,
    method: 'POST',
    auth: true,
  },
  brandAll: {
    url: `/v1/equipment/device/authorize`,
    method: 'POST',
    auth: true,
  },
  drinkSpec: {
    url: `/v1/equipment/device/drinkSpec`,
    method: 'GET',
    auth: true,
  },
}





