<template>
  <div class="manager">
    <el-row class="manager-filter">
      <el-col :span="10">
<!--        <el-radio-group v-model="showType" size="mini" border >-->
<!--          <el-radio-button label="menu"><i class="el-icon-menu"></i></el-radio-button>-->
<!--          <el-radio-button label="list"><i class="el-icon-s-operation"></i></el-radio-button>-->
<!--        </el-radio-group>-->
        <p  v-if="mode === 'choose'">可选文件数量: <span class="number">{{this.limit}}</span></p>
      </el-col>
      <el-col :span="14" style="text-align: right">
        <div class="manager-filter-opr">
          <el-input placeholder="搜索我的文件" size="small" v-model="pagination.name">
            <template slot="append">
              <el-button @click="loadFiles">搜索</el-button>
            </template>
          </el-input>

          <el-popover
            v-model="dir.show"
            placement="right"
            width="400"
            trigger="click">
            <div>
              <el-form label-width="120px">
                <el-form-item
                  style="margin-bottom: 4px"
                  label="文件夹名称">
                  <el-input v-model="dir.name" size="small"/>
                </el-form-item>

                <el-form-item style="margin-bottom: 0">
                  <el-button type="primary" size="small" style="margin-left: 16px"
                             @click="handleDirCreate">保存
                  </el-button>
                </el-form-item>
              </el-form>
            </div>
            <el-button slot="reference" type="primary" class="upload" plain size="small">新建文件夹</el-button>
          </el-popover>
          <el-button type="primary" class="upload" size="small" @click="$refs.upload.click()">上传文件</el-button>
          <input type="file" ref="upload" style="display: none" value=""
                 :accept="accept" @change="handleUpload">
        </div>
      </el-col>
    </el-row>

    <div class="manager-border">
      <el-row class="manager-file" v-if="dirs.length >0">
        <el-col :span="1" class="manager-file-back" @click.native="handleBack(0, true)">
          <i class="el-icon-arrow-left"></i>
        </el-col>
        <el-col :span="23">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item
              class="breadcrumb-item"
              @click.native="handleBack(idx)"
              v-for="(dir, idx) in dirs" :key="idx">{{dir.name}}
            </el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
      </el-row>

      <div class="manager-file-container">
        <div class="manager-item" v-for="(item, idx) in items" :key="idx"
             @click="handleDir(item)"
             :class="{dir: item.type === 'wenjianjia', active: item.active, 'manager-bg': item.fileType === 2}">
          <icon :icon="item.type" :clazz="'mp'" v-if="item.fileType !== 2"></icon>
          <el-image :src="item.url" v-else></el-image>
          <p class="manager-item-name">{{item.name}}</p>
        </div>
        <empty style="text-align: center" v-if="items.length === 0"></empty>
      </div>

      <el-pagination
        @current-change="handleNext"
        class="page"
        :current-page="pagination.curPage"
        :page-size="pagination.limit"
        layout="total, prev, pager, next"
        :total="pagination.total"></el-pagination>

    </div>
    <div class="manager-check" v-if="mode === 'choose'">
      <p class="manager-check-title">已选文件</p>
      <div class="manager-check-container">
        <div class="manager-item" v-for="(item, idx) in select" :key="idx"
             @dblclick="handleDir(item)"
             :class="{'manager-bg': item.fileType === 2}"
             @click="handleRemove(item, idx)">
          <icon :icon="item.type" :clazz="'font'" v-if="item.fileType !== 2"></icon>
          <el-image :src="item.url" v-else></el-image>
          <p class="manager-item-name">{{item.name}}</p>
          <i class="el-icon-error close"></i>
        </div>
      </div>

      <div class="manager-check-button">
        <el-button size="small" @click="$emit('files', [])">取消</el-button>
        <el-button type="primary" size="small" @click="$emit('files', select)">确定</el-button>
      </div>
    </div>
  </div>
</template>

<script>
  import Icon from './icon'

  import {fileList, fileUnion, auth, createDir} from "@/api/space/file";
  import BMF from "browser-md5-file";
  import axios from 'axios'
  import {mapGetters} from "vuex";

  const bmf = new BMF();
  export default {
    name: '',
    components: {
      Icon
    },
    props: {
      isMd5: {
        type: Boolean,
        default: false,
      },
      limit: {
        type: Number,
        default: -1,
      },
      fileType: {
        type: String,
        default: 'audio,video,image'
      },
      fileTypeVal: {
        type: String,
        default: ''
      },
      mode: {
        type: String,
        default: '',
      },
    },
    computed: {
      ...mapGetters(['prove'])
    },
    mounted: function () {
      this.loadFiles()
      let fileTypes = this.fileType.split(`,`) || []
      fileTypes.forEach((f) => {
        this.accept = this.fileTypeAccept(f)
      })

    },
    data: function () {
      return {
        showType: 'menu',
        pagination: {
          curPage: 1,
          limit: 21,
          total: 0,
          name: '',
        },
        accept: '*',
        file: {
          url: '',
          name: '',
          md5: '',
        },
        dir: {
          name: '',
          proveName: '',
          parentUuid: '',
          show: false,
        },
        dirs: [],
        items: [],
        select: [],
        imgTypes: ['image/'],
        audioTypes: ["audio/"],
        videoTypes: ["video/"],
        pptTypes: ["application/vnd.ms-powerpoint",
          "application/vnd.openxmlformats-officedocument.presentationml.presentation"],
        wordTypes: ["application/msword", ".docx"],
        xlsTypes: ["application/vnd.ms-excel",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"],
        pdfTypes: ["application/pdf"],
      }
    },
    methods: {
      fileTypeIcon(file) {
        switch (file.fileType) {
          case 1:
            return `wenjianjia`
          case 2:
            return `tupian`
          case 3:
            return `music`
          case 4:
            return `video`
          case 5:
            return `ppt`
          case 6:
            return `word`
          case 7:
            return `xls`
          case 8:
            return `pdf`
          default:
            return 'weizhiwenjian'
        }
      },
      fileTypeAccept(fileType) {
        switch (fileType) {
          case 'image':
            return this.imgTypes.join('') + '*'
          case 'audio':
            return this.audioTypes.join('') + '*'
          case 'video':
            return this.videoTypes.join('') + '*'
          case 'ppt':
            return this.pptTypes.join(',')
          case 'word':
            return this.wordTypes.join(',')
          case 'xls':
            return this.xlsTypes.join(',')
          case 'pdf':
            return this.pdfTypes.join(',')
          default:
            return ''
        }
      },

      handleDirCreate() {
        if (!this.dir.name) {
          this.$message.warning('请输入文件夹名称')
          return
        }
        let dirUuid = ``
        if (this.dirs.length > 0) {
          dirUuid = this.dirs[this.dirs.length - 1].uuid
        }
        this.dir.proveName = this.prove.name
        this.dir.parentUuid = dirUuid

        createDir(this.dir).then(() => {
          this.dir.show = false
          this.dir.name = ''
          this.$message.success('创建成功')
          this.loadFiles()
        })
      },

      handleUpload(e) {
        let file = e.target.files[0]
        let fileType = this._fileType(file.type)
        let {size, name} = file
        let employeeName = this.prove.name
        let dir = ``
        if (this.dirs.length > 0) {
          dir = this.dirs[this.dirs.length - 1].url
        }
        let vue = this
        if (this.isMd5) {
          bmf.md5(
            file,
            (err, md5) => {
              vue.file.md5 = md5;
            },
            progress => {
              console.log('progress number:', progress);
            },
          );
        }
        let type = fileType
        return auth({size, type, employeeName, dir, name}).then(res => {
          if (res.code == 0) {
            let fileName = res.data.fileName
            let {host, expire, policy, accessId, signature, dir, callback} = res.data.policy
            let param = {
              'key': dir + fileName,
              policy,
              'OSSAccessKeyId': accessId,
              'success_action_status': '200', //让服务端返回200,不然，默认会返回204
              signature,
              callback,
              expire
            }
            this.file = {name: name, url: host + dir + fileName, md5: vue.file.md5}
            let formData = new FormData();
            let keys = Object.keys(param)
            for (let i = 0; i < keys.length; i++) {
              let val = param[keys[i]]
              if (val) {
                formData.append(keys[i], val);
              }
            }
            formData.append('file', e.target.files[0]);
            let url = host;
            let config = {
              headers: {'Content-Type': 'multipart/form-data'}
            };
            axios.post(url, formData, config).then(() => {
              vue.loadFiles()
              vue.fileClean()
            }).catch(() => {
              vue.fileClean()
            })
          } else {
            return new Promise.reject()
          }
        }).catch(() => {
          return new Promise.reject()
        })
      },

      fileClean() {
        this.$refs.upload.value = ""
      },

      _fileType(suffix) {
        let type = 9
        if (suffix.toLowerCase().indexOf(this.imgTypes.join('')) >= 0) {
          type = 2
        } else if (suffix.toLowerCase().indexOf(this.audioTypes.join('')) >= 0) {
          type = 3
        } else if (suffix.toLowerCase().indexOf(this.videoTypes.join('')) >= 0) {
          type = 4
        } else if (suffix.toLowerCase().indexOf(this.pptTypes.join('')) >= 0) {
          type = 5
        } else if (suffix.toLowerCase().indexOf(this.wordTypes.join('')) >= 0) {
          type = 6
        } else if (suffix.toLowerCase().indexOf(this.xlsTypes.join('')) >= 0) {
          type = 7
        } else if (suffix.toLowerCase().indexOf(this.pdfTypes.join('')) >= 0) {
          type = 8
        }

        return type
      },

      fileTransfer(items) {
        this.items = items || []
        let vue = this
        this.items = this.items.map((item) => {
          let idx = vue.select.findIndex((i) => {
            return i.uuid === item.uuid
          })
          return {
            ...item,
            active: idx >= 0,
            type: this.fileTypeIcon(item),
          }
        })
      },
      loadFiles() {
        this.pagination.start = 0
        this.pagination.fileType = this.fileTypeVal
        fileUnion(this.pagination).then(res => {
          this.fileTransfer(res.data.list)
          this.pagination.total = parseInt(res.data.total)
        })
      },
      handleNext(nxt) {
        this.pagination.start = (nxt - 1) * this.pagination.limit
        fileList(this.pagination).then(res => {
          this.fileTransfer(res.data)
        })
      },
      handleRemove(item, idx) {
        let i = this.items.findIndex((i) => {
          return i.uuid === item.uuid
        })
        if (i >= 0) {
          this.items[i].active = false
        }
        this.select.splice(idx, 1)
      },
      handleDir(item) {
        if (item.type !== 'wenjianjia') {
          this.handleSelectFile(item)
          return
        }
        this.dirs.push(item)
        this.pagination.dir = item.url
        this.loadFiles()
      },
      handleSelectFile(item) {
        if (item.active) {
          item.active = false
          let idx = this.select.findIndex((i) => {
            return i.uuid === item.uuid
          })
          this.select.splice(idx, 1)
        } else {
          if (this.select.length +1  > this.limit && this.limit > 0) {
            this.$message.warning(`最多可选文件数量: ${this.limit} `)
            return
          }
          item.active = true
          this.select.push(item)
        }


      },
      handleBack(idx, force = false) {
        if (force) {
          if (this.dirs.length === 1) {
            this.dirs = []
            this.pagination.dir = ''
          } else {
            this.pagination.dir = this.dirs[this.dirs.length -2].url
            this.dirs.splice(this.dirs.length - 1, 1)
          }
          this.loadFiles()
          return
        }
        if (idx === this.dirs.length - 1 && this.dirs.length !== 1) {
          return
        }

        this.pagination.dir = this.dirs[idx].url
        this.loadFiles()
        if (this.dirs.length > 1) {
          this.dirs.splice(idx + 1, this.dirs.length - idx - 1)
        } else {
          this.dirs = []
        }

      },
    },
  }
</script>

<style lang="scss">
  .manager {
    display: flex;
    flex-direction: column;
    min-height: 480px;

    &-filter {
      .number{
        @include font-large-s();
        color: black;
      }
      &-opr {
        display: flex;
        align-items: center;
        max-width: 480px;
        margin-left: auto;

        .upload {
          margin-left: $small-space;
        }
      }
    }

    .breadcrumb-item {
      .el-breadcrumb__inner:hover {
        cursor: pointer;
        color: $theme-color;
      }

    }

    .el-image {
      width: 48px;
      height: 48px;
    }


    .mp {
      width: 48px;
      height: 48px;
      margin-bottom: $small-space;
    }

    &-file-container {
      padding: $middle-space;
      min-height: 286px;
    }

    &-check {
      &-title {
        margin-bottom: $small-space;
      }

      &-container {
        min-height: 100px;
        border: 1px solid $main-border-color;
      }

      &-button {
        margin: $middle-space 0;
        text-align: center;
      }

    }

    &-bg {
      background: #f2f2f270;
    }

    &-item {
      width: 96px;
      max-height: 170px;
      text-align: center;
      display: inline-block;
      padding: $small-space;
      margin: $small-space;
      padding-bottom: 4px;
      position: relative;
      border: 1px solid transparent;
      cursor: pointer;
      vertical-align: middle;
      border-radius: 4px;


      &-name {
        @include font-little-h();
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        word-break: break-all;
      }

      .close {
        position: absolute;
        right: $small-space;
        top: $small-space;
      }
    }

    &-item:not(.dir):hover, .active {
      border-color: $theme-color;
      background: $theme-dark-9;

      &-name {
        color: $color-black;
      }
    }

    .page {
      text-align: center;
    }

    &-border {
      flex: 1;
      margin: $middle-space 0;
      border: 1px solid $main-border-color;
    }

    &-file {
      padding: $small-space;
      border-bottom: 1px solid $main-border-color;

      &-back {
        text-align: center;
        cursor: pointer;
      }

      &-back:hover {
        color: $theme-color;
      }
    }
  }
</style>
