/* eslint-disable */
export function map(ak) {
  return new Promise(function (resolve, reject) {
    // window.onBMapCallback = function () {
    //   window.BMap = BMap;
    // };
    // console.log('enter')
    var script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "https://api.map.baidu.com/api?v=2.0&ak="+ak+"&callback=init";
    script.onerror = reject;
    document.body.appendChild(script);
  })
}
