<template>
  <svg
    :class="svgClass"
    aria-hidden="true"
  >
    <use :xlink:href="iconName" />
  </svg>
</template>

<script>
  export default {
    name: 'Icon',
    props: {
      icon: {
        type: String,
        required: true
      },
      clazz: {
        type: String,
        default: ''
      }
    },
    computed: {
      iconName () {
        return `#xm-${this.icon}`
      },
      svgClass () {
        if (this.clazz) {
          return 'svg-icon ' + this.clazz
        } else {
          return 'svg-icon'
        }
      }
    }
  }
</script>

<style scoped>
  .svg-icon {
    width: 1em;
    height: 1em;
    vertical-align: -0.15em;
    fill: currentColor;
    overflow: hidden;
    stroke: #fff;
  }
</style>
