export default {
  path: 'org',
  name: 'system.org',
  meta:{
    title: '组织管理'
  },
  component: () => import('@/views/redirect'),
  redirect: {name: 'system.org.account'},
  children:[
    {
      path: 'account',
      name: 'system.org.account',
      meta:{
        title: '入驻组织'
      },
      component: () => import('@/views/System/Org/Account/index'),
    },
  ]
}
