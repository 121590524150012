const type = {
  //login
  ACCOUNT_NAV_MENU_SET: `ACCOUNT_NAV_MENU_SET`,
  ACCOUNT_MENU_SET: `ACCOUNT_MENU_SET`,
  ACCOUNT_CHILD_MENU_SET: `ACCOUNT_CHILD_MENU_SET`,

  ACCOUNT_PROVE_AND_ORG: `ACCOUNT_PROVE_AND_ORG`,

}
export default type




