export default {
  path: 'query',
  name: 'order.query',
  meta: {
    title: '订单查询',
  },
  component: () => import('@/views/Order/Query/index'),
  children: [
    {
      path: 'device',
      name: 'order.query.device',
      component: () => import('@/views/Order/Query/device'),
      meta: {
        title: '设备订单',
        collapse: true,
      }
    },
    {
      path: 'o2o',
      name: 'order.query.o2o',
      component: () => import('@/views/Order/Query/o2o'),
      meta: {
        title: '商城订单',
        collapse: true,
      }
    },
  ]
}
