import overview from './overview.js'
import customer from './customer.js'

export default {
  path: 'salesman',
  name: 'salesman',
  meta:{
    title: '客户',
    redirect: true,
  },
  component: () => import('@/views/redirect'),
  children: [
    {...overview},
    {...customer},
  ]
}
