<template>
  <div class="time-range">
    <el-date-picker
      size="small"
      @change="handleChange"
      v-model="makeTimeRange"
      value-format="timestamp"
      type="datetimerange"
      range-separator="至"
      start-placeholder="开始日期"
      end-placeholder="结束日期">
    </el-date-picker>

    <p class="time-tags">
      <span class="time-tag" @click="handleDay(0)">今天</span>
      <span class="time-tag" @click="handleYesterday">昨天</span>
      <span class="time-tag" @click="handleDay(7)">最近7天</span>
      <span class="time-tag" @click="handleDay(30)">最近30天</span>
    </p>


  </div>
</template>

<script>
  export default {
    name: 'time-range',
    components: {},
    mounted: function () {
    },
    data: function () {
      return {
        makeTimeRange: [],
      }
    },
    methods: {
      handleYesterday() {
        const end = new Date();
        const start = new Date();
        end.setHours(0, 0, 0, 0)
        start.setHours(0, 0, 0, 0)
        start.setTime(start.getTime() - 3600 * 1000 * 24);
        this.makeTimeRange = [start, end]
        this.$emit('change', [start.getTime() / 1000, end.getTime() / 1000])
      },

      handleDay(day) {
        const end = new Date();
        const start = new Date();
        start.setHours(0, 0, 0, 0)
        end.setHours(23, 59, 59, 0)
        start.setTime(start.getTime() - 3600 * 1000 * 24 * day);
        this.makeTimeRange = [start, end]
        this.$emit('change', [start.getTime() / 1000, end.getTime() / 1000])
      },

      handleChange() {
        if (this.makeTimeRange && this.makeTimeRange.length > 0) {
          this.$emit('change', [this.makeTimeRange[0] / 1000, this.makeTimeRange[1] / 1000])
        } else {
          this.$emit('change', [0, 0])
        }
      }

    },
  }
</script>

<style lang="scss" scoped>
  .time-range {
    display: flex;
    .el-date-editor {
      margin-right: $small-space;
    }

    .time-tags{
      flex: 1;

    }

    .time-tag {
      color: $theme-color;
      margin: 0 $small-space;
      cursor: pointer;
      font-weight: 500;
    }
  }
</style>
