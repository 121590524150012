/**
 * 接口列表文件
 */

import mkt from "./mkt/api"
import equipment from "./equipment/api"
import account from "./account/api"
import org from "./org/api"
import message from "./message/api"

export default {

  /**  账户板块 ↓ **/
  account: account,

  /**  营销板块 ↓ **/
  mkt: mkt,

  /**  设备板块 ↓ **/
  equipment: equipment,

  /**  组织板块 ↓ **/
  org: org,

  /**  消息板块 ↓ **/
  message: message,

};
