import shop from './shop.js'
import goods from './goods.js'
import setting from './setting.js'
import member from './member.js'
import spread from './spread.js'
export default {
  path: 'mkt',
  name: 'mkt',
  meta:{
    title: '营销',
    redirect: true,
  },
  component: () => import('@/views/redirect'),
  children: [
    {...shop},
    {...goods},
    {...setting},
    {...member},
    {...spread},
  ]
}
