export default (Vue) => {
  Vue.filter('formatDate', function (value) {
    if (!value) {
      return ''
    }
    let d = new Date(value * 1000);
    let year = d.getFullYear();
    let month = d.getMonth() + 1;
    month = month < 10 ? '0' + month : month
    let date = d.getDate();
    date = date < 10 ? '0' + date : date
    let h = d.getHours();
    h = h < 10 ? '0' + h : h;
    let m = d.getMinutes();
    m = m < 10 ? '0' + m : m;
    let s = d.getSeconds();
    s = s < 10 ? '0' + s : s;
    return `${year}-${month}-${date} ${h}:${m}:${s}`;
  })

  Vue.filter('getdate', function (value) {
    return value.split(' ')[0]
  })
  Vue.filter('filterPay', function (style) {

    switch (style) {
      case 0:
        return '暂未支付'
      case 1:
        return '无支付'
      case 2:
        return '微信支付'
      case 3:
        return '支付宝'
    }
  })
  Vue.filter('filterStatus', function (status) {

      switch (status) {
        case 1:
          return '未支付'
        case 2:
          return '等待制作'
        case 3:
          return '部分完成'
        case 5:
          return '已完成'
      }
  })
  Vue.filter('gettime', function (value) {
    return value.split(' ')[1]
  })

  Vue.filter('applyStatus', function (val) {
    switch (val) {
      case 1:
        return '审核中'
      case 2:
        return '被驳回'
      case 3:
        return '信息无效'
      case 4:
        return '等待签约'
      case 5:
        return '开通成功'
    }
  })

  Vue.filter('F1000', function (val) {

    if (typeof (val) === 'number') {

      return (val).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')

    }

  })

  Vue.filter('I1000', function (val) {

    if (typeof (val) === 'number') {
      return (val).toFixed(0).replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')

    }

  })

  Vue.filter('formatMoney', function (value) {
    return (value / 100).toFixed(2)
  })
}
