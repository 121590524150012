export default {
  path: 'finance',
  name: 'org.finance',
  component: () => import('@/views/redirect'),
  meta: {
    title: '费用结算',
    redirect: true,
  },
  children: [
    {
      path: 'fee',
      name: 'org.finance.fee',
      component: () => import('@/views/Org/Finance/fee'),
      meta: {
        title: '费用充值'
      },
    },
    {
      path: 'fex',
      name: 'org.finance.fex',
      component: () => import('@/views/Org/Finance/fex'),
      meta: {
        title: '发票管理'
      },
    },
    {
      path: 'consume',
      name: 'org.finance.consume',
      component: () => import('@/views/Org/Finance/consume'),
      meta: {
        title: '月度结算'
      },
    }
  ]
}
