export default {
  union: {
    url: '/v1/mkt/shop/union',
    auth: true,
    method: 'GET',
  },
  list: {
    url: '/v1/mkt/shop/list',
    auth: true,
    method: 'GET',
  },
  delete: {
    url: '/v1/mkt/shop/delete',
    auth: true,
    method: 'GET',
  },
  create: {
    url: '/v1/mkt/shop/create',
    auth: true,
    method: 'POST',
  },
  update: {
    url: '/v1/mkt/shop/update',
    auth: true,
    method: 'POST',
  },
  detail: {
    url: '/v1/mkt/shop/detail',
    auth: true,
    method: 'GET',
  },
  // 分类信息
  sorts: {
    url: '/v1/mkt/shop/sorts',
    auth: true,
    method: 'GET',
  },

  sortCreate: {
    url: '/v1/mkt/shop/sortCreate',
    auth: true,
    method: 'POST',
  },
  sortUpdate: {
    url: '/v1/mkt/shop/sortUpdate',
    auth: true,
    method: 'POST',
  },
  sortDelete: {
    url: '/v1/mkt/shop/sortDelete',
    auth: true,
    method: 'GET',
  },

  goodsUnbindQuery: {
    url: '/v1/mkt/shop/goodsUnbindQuery',
    auth: true,
    method: 'GET',
  },

  goods: {
    url: '/v1/mkt/shop/goods',
    auth: true,
    method: 'GET',
  },

  sortGoodBind: {
    url: '/v1/mkt/shop/sortGoodsBind',
    auth: true,
    method: 'POST',
  },

  sortGoodsDelete: {
    url: '/v1/mkt/shop/sortGoodsDelete',
    auth: true,
    method: 'GET',
  },

  sortGoodsUpdate: {
    url: '/v1/mkt/shop/sortGoodsUpdate',
    auth: true,
    method: 'POST',
  },

  devices: {
    url: '/v1/mkt/shop/devices',
    auth: true,
    method: 'GET',
  },

  bindDevice: {
    url: '/v1/mkt/shop/bindDevice',
    auth: true,
    method: 'POST',
  },

  unBindDevice: {
    url: '/v1/mkt/shop/unBindDevice',
    auth: true,
    method: 'POST',
  },

  oprDevicePayMode: {
    url: '/v1/mkt/shop/oprDevicePayMode',
    auth: true,
    method: 'POST',
  },

  unBindDevices: {
    url: '/v1/mkt/shop/unBindDevices',
    auth: true,
    method: 'GET',
  }
}
