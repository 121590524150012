
export default {
  union: {
    url: `/v1/equipment/drinks/union`,
    method: 'GET',
    auth: true,
  },
  list: {
    url: `/v1/equipment/drinks/list`,
    method: 'GET',
    auth: true,
  },
  detail: {
    url: `/v1/equipment/drinks/detail`,
    method: 'GET',
    auth: true,
  },
  update: {
    url: `/v1/equipment/drinks/update`,
    method: 'POST',
    auth: true,
  },
  create: {
    url: `/v1/equipment/drinks/create`,
    method: 'POST',
    auth: true,
  },
  delete: {
    url: `/v1/equipment/drinks/delete`,
    method: 'POST',
    auth: true,
  },
}

