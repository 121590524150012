export default {
  create: {
    url: '/v1/mkt/pay/create',
    auth: true,
    method: 'POST',
  },
  union: {
    url: '/v1/mkt/pay/union',
    auth: true,
    method: 'GET',
  },
  list: {
    url: '/v1/mkt/pay/list',
    auth: true,
    method: 'GET',
  },
  chanelInfo: {
    url: '/v1/mkt/pay/chanelInfo',
    auth: true,
    method: 'GET',
  },
  chanelDefault: {
    url: '/v1/mkt/pay/chanelDefault',
    auth: true,
    method: 'GET',
  },
  default: {
    url: '/v1/mkt/pay/default',
    auth: true,
    method: 'GET',
  },
  wxApply: {
    url: '/v1/mkt/pay/wxApply',
    auth: true,
    method: 'POST',
  },
  bindDrCoffee: {
    url: '/v1/mkt/pay/bindDrCoffee',
    auth: true,
    method: 'POST',
  },
  wxApplyDetail: {
    url: '/v1/mkt/pay/wxApplyDetail',
    auth: true,
    method: 'GET',
  },
  wxSub: {
    url: '/v1/mkt/pay/wxSub',
    auth: true,
    method: 'GET',
  },
  aliApply: {
    url: '/v1/mkt/pay/aliApply',
    auth: true,
    method: 'GET',
  },
  logUnion: {
    url: '/v1/mkt/pay/logUnion',
    auth: true,
    method: 'GET',
  },
  logs: {
    url: '/v1/mkt/pay/logs',
    auth: true,
    method: 'GET',
  },
  authorizeList: {
    url: '/v1/mkt/pay/authorizeList',
    auth: true,
    method: 'GET',
  },
  authorizeCancel: {
    url: '/v1/mkt/pay/authorizeCancel',
    auth: true,
    method: 'POST',
  },
  authorize: {
    url: '/v1/mkt/pay/authorize',
    auth: true,
    method: 'POST',
  },
  delete: {
    url: '/v1/mkt/pay/delete',
    auth: true,
    method: 'GET',
  },

}
