/**
 *  Shopro全局配置文件
 *  @version 1.2.0
 */


const ENV_BASE_URL = {
	development: 'http://localhost:18218', //开发环境
	production: 'https://api.yunka-it.cn', //生产环境
}
const ENV_API_URL = {
	development: `${ENV_BASE_URL.development}`, //开发环境
	production: `${ENV_BASE_URL.production}`, //生产环境
}

export const BASE_URL = ENV_BASE_URL[process.env.NODE_ENV || 'development']; //后台根域名
export const API_URL = ENV_API_URL[process.env.NODE_ENV || 'development']; //后台接口域名
