export default {
  relate: {
    url: `/v1/account/org/relate`,
    method: 'GET',
    auth: true,
  },

  balanceAlarm: {
    url: `/v1/account/org/balanceAlarm`,
    method: 'GET',
    auth: true,
  },

  balanceAlarmSet: {
    url: `/v1/account/org/balanceAlarmSet`,
    method: 'POST',
    auth: true,
  },

  infoUnion: {
    url: `/v1/account/org/infoUnion`,
    method: 'GET',
    auth: true,
  },

  modify: {
    url: `/v1/account/org/modify`,
    method: 'POST',
    auth: true,
  },

  union: {
    url: `/v1/account/org/union`,
    method: 'GET',
    auth: true,
  },

  list: {
    url: `/v1/account/org/list`,
    method: 'GET',
    auth: true,
  },

}


