import user from './api-user'
import role from './api-role'
import prove from './api-prove'
import department from './api-department'

export default {
  user: user,
  role: role,
  prove: prove,
  department: department,
}
