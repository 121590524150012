export default {
  path: 'customer',
  name: 'salesman.customer',
  component: () => import('@/views/redirect'),
  redirect: {name: 'salesman.customer.query'},
  children:[
    {
      path: 'index',
      name: 'salesman.customer.query',
      meta:{
        title: '客户信息'
      },
      component: () => import('@/views/Salesman/Customer'),
    },
    {
      path: 'detail/:uuid',
      name: 'salesman.customer.detail',
      meta:{
        title: '客户详情',
        hidden: true
      },
      component: () => import('@/views/Salesman/Customer/Detail'),
    },
    {
      path: 'tag',
      meta:{
        title: '客户画像',
      },
      name: 'salesman.customer.tag',
      component: () => import('@/views/Salesman/Customer/Tag'),
    },
  ]
}
