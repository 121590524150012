export default {
  list: {
    url: `/v1/org/department/list`,
    method: 'GET',
    auth: true,
  },
  tree: {
    url: `/v1/org/department/tree`,
    method: 'GET',
    auth: true,
  },
  create: {
    url: `/v1/org/department/create`,
    method: 'POST',
    auth: true,
  },
  update: {
    url: `/v1/org/department/update`,
    method: 'POST',
    auth: true,
  },
  delete: {
    url: `/v1/org/department/delete`,
    method: 'GET',
    auth: true,
  },
  proveUnion: {
    url: `/v1/org/department/proveUnion`,
    method: 'GET',
    auth: true,
  },
  proveList: {
    url: `/v1/org/department/proveList`,
    method: 'GET',
    auth: true,
  },
  proveBind: {
    url: `/v1/org/department/proveBind`,
    method: 'POST',
    auth: true,
  },
  proveUnbind: {
    url: `/v1/org/department/proveUnbind`,
    method: 'POST',
    auth: true,
  },

}
