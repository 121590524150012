import request from '@/utils/request'

/**
 * 获取文件上传授权
 */
export function auth(data) {
  return request({
    url: '/v1/space/file/oauth',
    method: 'post',
    data
  })
}

export function fileUnion(params) {
  return request({
    url: '/v1/space/file/union',
    method: 'GET',
    params
  })
}

export function fileList(params) {
  return request({
    url: '/v1/space/file/list',
    method: 'GET',
    params
  })
}

export function createDir(data) {
  return request({
    url: '/v1/space/dir/create',
    method: 'post',
    data
  })
}



