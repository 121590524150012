export default {
  union: {
    url: '/v1/mkt/order/union',
    auth: true,
    method: 'GET',
  },
  list: {
    url: '/v1/mkt/order/list',
    auth: true,
    method: 'GET',
  },

  shopUnion: {
    url: '/v1/mkt/order/shopUnion',
    auth: true,
    method: 'GET',
  },
  shopList: {
    url: '/v1/mkt/order/shopList',
    auth: true,
    method: 'GET',
  },

  detail: {
    url: '/v1/mkt/order/detail',
    auth: true,
    method: 'GET',
  },
  refund: {
    url: '/v1/mkt/order/refund',
    auth: true,
    method: 'POST',
  },


}
