import Vue from 'vue'
import VueRouter from 'vue-router'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}

VueRouter.prototype.replace = function replace(location) {
  return originalPush.call(this, location).catch((err) => err)
}


Vue.use(VueRouter)

import system from './system/index.js'
import salesman from './salesman/index.js'
import org from './org/index.js'
import order from './order/index.js'
import wms from './wms/index.js'
import equipment from './equipment/index.js'
import mkt from './mkt/index.js'
import test from './test.js'

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login'),
  },
  {...test},
  {
    path: '/',
    name: 'home',
    component: () => import('@/layout/main'),
    children: [
      {...system},
      {...salesman},
      {...org},
      {...order},
      {...wms},
      {...equipment},
      {...mkt},
    ]
  },
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
