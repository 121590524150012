export default {
  path: 'setting',
  name: 'mkt.setting',
  meta: {
    title: '运营设置',
    redirect: true,
  },
  component: () => import('@/views/redirect'),
  children: [
    {
      path: 'chanel',
      name: 'mkt.setting.chanel',
      component: () => import('@/views/Mkt/Setting/Chanel'),
      meta: {
        title: '渠道设置',
      }
    },
    {
      path: 'logistics',
      name: 'mkt.setting.logistics',
      component: () => import('@/views/Mkt/Setting/Logistics/index'),
      meta: {
        title: '物流设置',
      }
    },
  ]
}
