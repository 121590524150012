export default {
  path: 'architecture',
  name: 'org.architecture',
  component: () => import('@/views/redirect'),
  meta: {
    title: '人员岗位',
    redirect: true,
  },
  children: [
    {
      path: 'user',
      name: 'org.architecture.user',
      component: () => import('@/views/Org/Architecture/user.vue'),
      meta: {
        bg: true,
        break: true,
        title: '员工信息'
      },
    },
    {
      path: 'department',
      name: 'org.architecture.department',
      component: () => import('@/views/Org/Architecture/department.vue'),
      meta: {
        bg: true,
        break: true,
        title: '部门架构'
      }
    },
    {
      path: 'role',
      name: 'org.architecture.role',
      component: () => import('@/views/Org/Architecture/role.vue'),
      meta: {
        bg: true,
        break: true,
        title: '岗位权限'
      }
    },
  ]
}
