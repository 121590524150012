import pay from './pay'
import query from './query'

export default {
  path: 'order',
  name: 'order',
  meta: {
    title: '订单',
    redirect: true,
  },
  component: () => import('@/views/redirect'),
  children: [
    {
      path: 'overview',
      name: 'order.overview',
      component: () => import('@/views/Order/Overview'),
      meta: {
        title: '订单概览',
      }
    },
    {
      path: 'analyze',
      name: 'order.analyze',
      component: () => import('@/views/Order/Analyze'),
      meta: {
        title: '趋势分析',
      }
    },
    {...query},
    {...pay}
  ]
}
