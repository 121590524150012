export default {
  path: 'member',
  name: 'mkt.member',
  meta: {
    title: '会员用户',
    redirect: true,
  },
  component: () => import('@/views/redirect'),
  children: [
    {
      path: 'user',
      name: 'mkt.member.user',
      component: () => import('@/views/Mkt/Member/User'),
      meta: {
        title: '用户数据',
      }
    },
    {
      path: 'detail/:uuid',
      name: 'mkt.member.user.detail',
      component: () => import('@/views/Mkt/Member/User/detail'),
      meta: {
        title: '用户详情',
      }
    },
    {
      path: 'garden',
      name: 'mkt.member.garden',
      component: () => import('@/views/Mkt/Member/Garden'),
      meta: {
        title: '会员等级',
      }
    },
    {
      path: 'garden/detail/:label',
      name: 'mkt.member.garden.detail',
      component: () => import('@/views/Mkt/Member/Garden/detail'),
      meta: {
        title: '会员详情',
      }
    },
  ]
}
