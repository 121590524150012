<template>
  <router-view class="app"/>
</template>
<script>

  import {getToken} from "@/utils/auth"
  import {mapActions} from "vuex";
  export default {
    data() {
      return {};
    },
    methods: {
      ...mapActions(['accountMenuSet', 'accountNavMenuSet', 'accountProveAndOrg']),
      handleMenu(module) {
        this.accountMenuSet(module.modules)
        if (this.$route.name.indexOf(module.engName) === -1) {
          this.$router.push({name: module.engName})
        }
      },
      async loadInfo(token) {
        await this.$api('org.user.union',{token: token}).then(data => {
          this.accountNavMenuSet(data.modules)
          this.accountProveAndOrg({prove: data.user, org: data.org})
        })
      }
    },
    mounted() {
      let token = getToken()
      if (token) {
        this.loadInfo(token)
      } else {
        this.$router.replace({name: 'login'})
      }
    },
  };
</script>
<style lang="scss" scoped>
  body, html {
    height: 100%;
    width: 100%;

    .app {
      height: 100%;
      width: 100%;
    }
  }
</style>
