

import finance from './finance.js'
import overview from './overview.js'
import information from './information.js'
import architecture from './architecture.js'
// import pay from './pay.js'
export default {
  path: 'org',
  name: 'org',
  meta:{
    title: '管理舱',
    redirect: true,
  },
  component: () => import('@/views/redirect'),
  children: [
    {...overview},
    {...information},
    {...finance},
    {...architecture},
  ]
}
