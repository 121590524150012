export default {
  path: 'spread',
  name: 'mkt.spread',
  meta: {
    title: '营销中心',
  },
  component: () => import('@/views/redirect'),
  redirect: {name: 'mkt.spread.main'},
  children: [
    {
      path: 'main',
      name: 'mkt.spread.main',
      component: () => import('@/views/Mkt/Spread/index'),
    },

  ]
}
