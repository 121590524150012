export default {
  path: 'wms',
  name: 'wms',
  meta: {
    title: '物料',
    redirect: true,
  },
  component: () => import('@/views/redirect'),
  children: [
    {
      path: 'material',
      name: 'wms.material',
      component: () => import('@/views/redirect'),
      redirect: {name: 'wms.material.info'},
      children: [
        {
          meta: {
            title: '基础物料'
          },
          path: 'info',
          name: 'wms.material.info',
          component: () => import('@/views/Wms/Material/info'),
        },
        {
          path: 'detail/:uuid',
          name: 'wms.material.detail',
          component: () => import('@/views/Wms/Material/detail'),
          meta: {
            title: '物料详情',
            hidden: true,
            bgClass: 'color-bg'
          }
        },
      ]
    },
    {
      path: 'fittings',
      name: 'wms.fittings',
      component: () => import('@/views/Wms/Fittings'),
      meta: {
        title: '仓储配件',
        bgClass: 'color-bg'
      }
    },
    {
      path: 'record',
      name: 'wms.record',
      component: () => import('@/views/Wms/Record'),
    },
  ]
}
