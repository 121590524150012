import Request from './request'
import apiList from './yunkapro.js'
import {Message} from 'element-ui';

export default function api(url, params = {}, data = {}, showToast = true) {
  const request = new Request();
  let api = getApiObj(url);
  request.interceptor.request((config, cancel) => { /* 请求之前拦截器 */
    if (api.auth) {
      let token = localStorage.getItem('token');
      if (!token) {
        cancel()
        throw ('暂未登录,已阻止此次API请求~');
      }

      config.params.token = token
    }

    return config
  });

  request.interceptor.response((response) => { /* 请求之后拦截器 */
    if (response.data.code !=='0') {
      if (showToast) {
        Message.error(response.data.msg);
        return Promise.reject(response.data.msg)
      }
    }

    if (response.status === 404) { // 服务端返回的状态码不等于200，则reject()
      Message.error('api 不存在');
      return Promise.reject('无授权')
    }

    if (response.status === 401) { // 服务端返回的状态码不等于200，则reject()
      localStorage.removeItem('token');
      return Promise.reject('无授权')
    }
    if (response.status === 500) { // 服务端返回的状态码不等于200，则reject()
      Message.error('网络异常');
      return Promise.reject('网络异常')
    }
    return response.data.data
  }, (response) => { // 预留可以日志上报
    return response
  })

  return request.request({
    url: api.url,
    params: params,
    data: data,
    method: api.method
  })

}

function getApiObj(url) {
  let apiArray = url.split(".");
  let api = apiList;
  apiArray.forEach(v => {
    api = api[v];
  });
  return api;
}
