
export default {
  all: {
    url: `/v1/equipment/brand/all`,
    method: 'GET',
    auth: true,
  },
  products: {
    url: `/v1/equipment/brand/products`,
    method: 'GET',
    auth: true,
  },

}


