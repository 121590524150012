<template>
  <div class="image-list">
    <template v-if="limit !== 1">
      <draggable
        v-if="draggable"
        v-model="list"
        v-show="list.length > 0"
        @change="handleDragEnd"
      >
        <div class="image-wrapper" :class="{'image-wrapper-first': index === 0}"
             :style="{width: width, height:height}"
             @click="handleViewImage(item)"
             v-for="(item,index) in list" :key="index">
          <div class="icon-container">
            <span class="preview" v-show="preview"> <i class="el-icon-search"></i> 预览</span>
            <span class="delete" @click.stop.prevent="handleDel(index)">
              <i class="el-icon-delete"></i> 删除
            </span>
          </div>
          <el-image :src="item" :style="{width: width, height:height}"/>
        </div>

      </draggable>

      <template v-else>
        <template v-if="list.length >0">
          <div class="image-wrapper"
               :style="{width: width, height:height}"
               :class="{'image-wrapper-first': index === 0}" @click="handleViewImage(item)"
               v-for="(item,index) in list" :key="index">
            <div class="icon-container">
              <span class="preview" v-show="preview"> <i class="el-icon-search"></i> 预览</span>
              <span class="delete" @click.stop.prevent="handleDel(index)">
              <i class="el-icon-delete"></i> 删除
            </span>
            </div>
            <el-image :src="item" :width="width" :height="height"/>
          </div>
        </template>

      </template>
    </template>

    <template v-else>
      <div class="image-wrapper" v-if="list.length > 0"
           :style="{width: width, height:height}"
           @click="handleViewImage(images[0])">
        <div class="icon-container">
          <span class="preview" v-show="preview"> <i class="el-icon-search"></i> 预览</span>
          <span class="delete" @click.stop.prevent="handleDel(0)">
              <i class="el-icon-delete"></i> 删除
            </span>
        </div>
        <el-image :src="list[0]"/>
      </div>
    </template>

    <el-image-viewer
      v-if="imgPreviewVisible"
      :on-close="()=>{ imgPreviewVisible = false }"
      :url-list="imgPreviewList"
    />
    <template>

    </template>

  </div>

</template>

<script>
  import draggable from 'vuedraggable'
  import ElImageViewer from 'element-ui/packages/image/src/image-viewer';

  export default {
    name: '',
    components: {
      draggable,
      ElImageViewer
    },
    props: {
      images: [Array, String],
      multiple: Boolean,
      limit: Number,
      draggable: {
        type: Boolean,
        default: true,
      },
      preview: {
        type: Boolean,
        default: true
      },
      width: {
        type: String,
        default: '150px'
      },
      height: {
        type: String,
        default: '150px'
      }
    },
    computed:{
      list: {
        get () {
          return this.images || []
        },
        set(value) {
          this.$emit('update:images', value)
        },
      }
    },
    data: function () {
      return {
        imgPreviewVisible: false,
        imgPreviewList: [],
      }
    },
    methods: {
      handleDel(index) {
        this.$emit('del', index)
      },
      handleViewImage(item) {
        this.imgPreviewList = [item]
        this.imgPreviewVisible = true
      },
      handleDragEnd() {
        this.$emit('drag', this.images)
      }
    },
  }
</script>

<style lang="scss">
  .image-list {
    .image-wrapper {
      position: relative;
      float: left;
      display: inline-block;
      cursor: pointer;
      margin-right: $middle-space;
      margin-bottom: $middle-space;
      border: 1px dashed $container-border-color;
      border-radius: 4px;

      img {
      }

      .icon-container {
        width: 100%;
        height: 100%;
        background: $remark-bg-color;
        text-align: center;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 99;
        display: none;

        span {
          display: inline-block;
          margin-top: 36%;
        }

        .preview {
          margin-right: $middle-space;
        }

        .delete {
          color: $brand-danger;
        }
      }
    }

    .image-wrapper:hover {
      .icon-container {
        display: block;
        color: $theme-color;
      }
    }

    .image-wrapper-first {
      box-sizing: border-box;
      border: 1px solid $theme-color;
      overflow: hidden;
    }

    .image-wrapper-first:before {
      content: "主图";
      position: absolute;
      font-size: 12px;
      text-align: center;
      font-family: PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
      width: 64px;
      height: 28px;
      line-height: 36px;
      transform: rotate(-45deg);
      transform-origin: 24% 136%;
      background: $theme-color;
      z-index: 10;
    }
  }
</style>
