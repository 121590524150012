/**
 * Created by fworld on 2019/9/11.
 */
import types from '../mutationTypes';


export default {
  state: {
    navMenus: [],
    buttons: [],
    menus: [],
    childMenu: {},
    prove: {},
    orgMenu: null,
    org: {
      proveName:'企业管理员'
    },

  },
  mutations: {
    [types.ACCOUNT_NAV_MENU_SET](state, module) {
      let button  = module.button || []
      for (let i = 0; i < module.button.length; i++) {
        button[module.button[i]] = true
      }
      state.navMenus = module.module || []
      let manager = null
      state.navMenus.forEach(v => {
        if (v.engName === 'org') {
          manager = v
        }
      })
      state.orgMenu = manager
      state.buttons = button
    },
    [types.ACCOUNT_MENU_SET](state, menus) {
      state.menus = menus
    },
    [types.ACCOUNT_CHILD_MENU_SET](state, menu) {
      state.childMenu = menu
      state.menus = menu.modules || []
    },
    [types.ACCOUNT_PROVE_AND_ORG](state, info) {
      state.prove = info.prove
      state.org = info.org
    },
  },
  actions: {
    accountNavMenuSet({commit}, info) {
      commit(types.ACCOUNT_NAV_MENU_SET, info)
    },
    accountMenuSet({commit}, info) {
      commit(types.ACCOUNT_MENU_SET, info)
    },
    accountChildMenusSet({commit}, info) {
      commit(types.ACCOUNT_CHILD_MENU_SET, info)
    },

    accountProveAndOrg({commit}, info) {
      commit(types.ACCOUNT_PROVE_AND_ORG, info)
    },
  }
}
