export default {
  path: 'pay',
  name: 'order.setting.pay',
  redirect:{name: 'order.setting.pay.list'},
  meta: {
    title:'订单设置',
    redirect: true,
  },
  component:() => import('@/views/redirect'),
  children:[
    {
      path: 'list',
      name: 'order.setting.pay.list',
      component: () => import('@/views/Order/Pay/List'),
      meta: {
        title:'支付管理',
      }
    },
    {
      path: 'create',
      name: 'order.setting.pay.create',
      component: () => import('@/views/Order/Pay/List/create'),
      meta: {
        title:'支付创建',
        hidden: true,
        bgClass: 'color-bg',
      }
    },
    {
      path: 'detail/:uuid',
      name: 'order.setting.pay.detail',
      component: () => import('@/views/Order/Pay/List/detail'),
      meta: {
        title:'支付编辑',
        hidden: true,
        bgClass: 'color-bg',
      }
    },
    {
      path: 'detail/:uuid/wx',
      name: 'order.setting.pay.detail.wx',
      component: () => import('@/views/Order/Pay/List/Wechat/wx'),
      meta: {
        title:'微信支付编辑',
        hidden: true,
        bgClass: 'color-bg',
      }
    }
  ]
}
