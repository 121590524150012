import shop from './api-shop'
import goods from './api-goods'
import user from './api-user'
import order from './api-order'
import pay from './api-pay'
import wx from './api-wx'

export default {
  shop: shop,
  goods: goods,
  user: user,
  order: order,
  pay: pay,
  wx: wx,
}
