<template>
  <el-dialog
    class="file"
    :visible.sync="show"
    top="10px"
    :append-to-body="true"
    title="选取文件"
    width="900px"
    :destroy-on-close="true"
  >
    <manager :file-type="fileType" :limit="limit" :file-type-val="fileTypeVal"
             @files="handleFiles"
             mode="choose"></manager>
  </el-dialog>
</template>

<script>
  import Manager from './manager'
  export default {
    name: '',
    components: {
      Manager
    },
    props: {
      visible: {
        type: Boolean,
        default: false
      },
      fileType: {
        type: String,
        default: 'image'
      },
      fileTypeVal: {
        type: String,
        default: ''
      },
      limit: {
        type: Number,
        default: 9999
      },
    },
    mounted: function () {
    },
    computed: {
      show: {
        get() {
          return this.visible
        },
        set(value) {
          this.$emit('update:visible', value)
          return value
        }
      },
    },
    data: function () {
      return {
      }
    },
    methods: {
      handleFiles(files) {
        this.$emit('files', files)
        this.show = false
      }
    },
  }
</script>

<style lang="scss">
  .file{
    .el-dialog__body {
      padding-bottom: 0;
    }

  }
</style>
