import './assets/style/reset.scss';
import "./assets/style/color.scss"
import "./assets/style/element.scss"
import ElementUI from 'element-ui';
import RouterBack from './components/RouterBack'
import Footer from './components/Footer'
import MaterialManager from './components/MaterialManager'
import StepContainer from './components/StepContainer'
import TimeRange from './components/TimeRange'
import Popconfirm from './components/Popconfirm'
import NProgress from "nprogress";
import './assets/style/nprogress.scss'

import VueQrcode from '@chenfengyuan/vue-qrcode';
import filters from './utils/filters'

import Empty from './components/Empty'
import EmptyTip from './components/EmptyTip'
import {map} from './assets/js/map'

import api from './api/index'

var $themeColor = '#f47720'


Date.prototype.Format = function (fmt) { // author: meizz
  var o = {
    "M+": this.getMonth() + 1, // 月份
    "d+": this.getDate(), // 日
    "h+": this.getHours(), // 小时
    "m+": this.getMinutes(), // 分
    "s+": this.getSeconds(), // 秒
    "q+": Math.floor((this.getMonth() + 3) / 3), // 季度
    "S": this.getMilliseconds() // 毫秒
  };
  if (/(y+)/.test(fmt))
    fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
  for (var k in o)
    if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
  return fmt;
}

String.prototype.firstUpperCase = function(){
  return this.replace(/^\S/, s => s.toUpperCase());
}


export default (Vue, router, store) => {
  filters(Vue)
  Vue.use(ElementUI)
  Vue.component(Empty.name, Empty);
  Vue.component(EmptyTip.name, EmptyTip);
  Vue.component(MaterialManager.name, MaterialManager);
  Vue.component(StepContainer.name, StepContainer);
  Vue.component(VueQrcode.name, VueQrcode);
  Vue.component(Footer.name, Footer)
  Vue.component(RouterBack.name, RouterBack)
  Vue.component(TimeRange.name, TimeRange)
  Vue.component(Popconfirm.name, Popconfirm)
  map(`vzC3nKa47IhytKb2GQNZ7RsGZgxiRoD9`)

  Vue.prototype.$api = api
  Vue.prototype.$themeColor = $themeColor

  Vue.prototype.$btnCheck = function (val) {
    return store.getters.buttons[val]
  }
  router.beforeEach((to, from, next) => {
    NProgress.start() // 显示进度条
    next()
  })

  router.afterEach(() => {
    NProgress.done() // 完成进度条
  })
}
