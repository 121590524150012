<template>
  <span class="back" @click="$router.back()"><i class="iconfont icon-fanhui"></i> {{name}}</span>
</template>

<script>
  export default {
    name: 'router-back',
    props: {
      name: {
        type: String,
        default: '返回',
      }
    },
    components: {},
    mounted: function () {
    },
    data: function () {
      return {}
    },
    methods: {},
  }
</script>

<style lang="scss" scoped>

  .back {
    cursor: pointer;
  }

  .back:hover {
    color: $theme-color;
  }


</style>
