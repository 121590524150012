/**
 * Created by fworld on 2019/12/15.
 */
export default {
  navMenus: state => state.account.navMenus, // 导航菜单
  menus: state => state.account.menus, // 导航菜单
  childMenu: state => state.account.childMenu, // 导航菜单
  prove: state => state.account.prove, // 导航菜单
  org: state => state.account.org, // 导航菜单
  orgMenu: state => state.account.orgMenu, // 导航菜单
  buttons: state => state.account.buttons, // 导航菜单
}
