<template>
  <div class="empty">
    <img :src="Empty"/>
    <slot>
      <p class="yk-tip">未找到搜索信息，用其他条件搜索试试</p>
    </slot>

  </div>
</template>

<script>
  import Empty from '@/assets/img/no-data.png'

  export default {
    name: 'empty-tip',
    components: {},
    mounted: function () {
    },
    data: function () {
      return {
        Empty: Empty,
      }
    },
    methods: {},
  }
</script>

<style lang="scss" scoped>
  .empty {
    margin: $middle-space auto;
    text-align: center;
    img {
      max-width: 320px;
      margin-top: $middle-space;
    }

  }
</style>
