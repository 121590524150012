<template>
  <div class="slog-container">
    <p>
      <span> Copyright © 2020-{{new Date().getFullYear()}} L-IOT v0.3.0-beta | 云咖信息为您更好使用物联云服务 苏ICP备20042152号-1</span>
    </p>

  </div>
</template>

<script>
  export default {
    name: 'layout-footer',
    components: {},
    mounted: function () {
    },
    data: function () {
      return {}
    },
    methods: {},
  }
</script>

<style lang="scss" scoped>
  .slog-container {
    padding-bottom: $small-space;
    padding-top: $small-space;
    color: $gray-7;
    text-align: center;
    @include font-little();
  }
</style>
