export default {
  path: 'drinks',
  name: 'equipment.drinks',
  component: () => import('@/views/redirect'),
  redirect: {name: 'equipment.drinks.list'},
  meta: {
    title:'饮品库',
  },

  children: [
    {
      path: 'list',
      name: 'equipment.drinks.list',
      component: () => import('@/views/Equipment/Drink/list'),
      meta: {
        title:'',
        bgClass: 'color-bg',
      },
    },
    {
      path: 'detail/:uuid',
      name: 'equipment.drinks.detail',
      component: () => import('@/views/Equipment/Drink/detail'),
      meta: {
        title: '编辑饮品',
        bgClass: 'color-bg',
        hidden: true,
      }
    },
    {
      path: 'create',
      name: 'equipment.drinks.create',
      component: () => import('@/views/Equipment/Drink/create'),
      meta: {
        title: '创建饮品',
        bgClass: 'color-bg',
        hidden: true,
      }
    },
  ]
}
