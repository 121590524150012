export default {
  path: 'shop',
  name: 'mkt.shop',
  meta: {
    title: '企业门店',
  },
  component: () => import('@/views/redirect'),
  redirect: {name: 'mkt.shop.list'},
  children: [
    {
      path: 'list',
      name: 'mkt.shop.list',
      component: () => import('@/views/Mkt/shops'),
    },
    {
      path: 'create',
      name: 'mkt.shop.create',
      component: () => import('@/views/Mkt/Shop/create'),
      meta: {
        title: '创建门店',
      }
    },
    {
      path: 'entity/:uuid',
      name: 'mkt.shop.entity',
      component: () => import('@/views/Mkt/Shop/index'),
      meta: {
        title: '门店详情',
        bgClass: 'color-bg',
        hidden: true,
      }
    },
  ]
}
