import module from './module.js'
import endpoint from './endpoint.js'
import org from './org.js'
export default {
  path: 'system',
  name: 'system',
  meta:{
    title: '系统',
    redirect: true,
  },
  component: () => import('@/views/redirect'),
  children: [
    {...module},
    {...endpoint},
    {...org}
  ]
}
