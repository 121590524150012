import device from './api-device'
import drinks from './api-drinks'
import brand from './api-brand'
import models from './api-models'

export default {
  device: device,
  drinks: drinks,
  brand: brand,
  models: models,
}
