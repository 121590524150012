<template>
  <div class="mm">
    <template v-if="fileType === 'image'">
      <image-list :images.sync="files" ref="images"
                  :limit="fileLimit"
                  @del="handleDel"
                  :width="width"
                  :height="height"
                  :preview="preview"
                  :draggable="draggable" @drag="handleDrag" ></image-list>
    </template>
    <!-- 视频-->
    <template v-if="fileType === 'video'">
      <video-list :videos.sync="files"
                  :width="width"
                  :height="height" ref="videos" :limit="fileLimit" @del="handleDel"></video-list>
    </template>
    <div class="mm-handle-upload"
         v-show="files.length < limit">
      <div class="item"
           :style="{width: width, height:height}"
           @click="visible=true">
        <div class="upload">
          <i class="el-icon-plus upload-icon"></i>
          <span class="upload-text">添加<template v-if="fileType === 'video'">视频</template><template
            v-if="fileType === 'image'">图片</template></span>
        </div>
      </div>
    </div>
    <file-picker :visible.sync="visible"
                 :file-type="fileType"
                 :file-type-val="fileTypeVal"
                 :multiple="multiple"
                 :limit="limit-(files && files.length)"
                 @files="handleChoose">
    </file-picker>
  </div>
</template>

<script>
  import ImageList from './image_list'
  import VideoList from './video_list'
  import FilePicker from './file_picker'

  export default {
    name: 'material-manager',
    props: {
      fileType: {
        type: String,
        default: 'image'
      },
      limit: {
        type: Number,
        default: -1
      },
      multiple: {
        type: Boolean,
        default: true
      },
      preview: {
        type: Boolean,
        default: true
      },
      draggable: {
        type: Boolean,
        default: true
      },
      width: {
        type: String,
        default: '150px'
      },
      height: {
        type: String,
        default: '150px'
      },
      value: [Array, String]
    },
    computed: {
      fileLimit() {
        return this.limit || -1
      },
    },
    watch: {
      value: {
        handler(v) {
          this.calcFiles(v)
        },
        deep: true,
      }
    },
    data() {
      return {
        visible: false,
        fileTypeVal:'',
        listType:'',
        files:[],
      }
    },
    mounted() {
      this.calcFiles(this.value)
      switch (this.fileType) {
      case `image`:
        this.fileTypeVal = '1,2'
              break
        case `video`:
          this.fileTypeVal = '1,4'
      }

      if (this.limit === 1) {
        this.listType = 'single'
      } else {
        this.listType = 'array'
      }
    },
    methods: {
      calcFiles(value) {
        if (this.limit !== 1) {
          if (this.value) {
            this.files = this.value
          } else {
            this.files = []
          }
        } else {
          let _files =  value || []
          let files = []
          _files.forEach((file) => {files.push(file) })
          this.files = files
        }
      },
      handleDel(index) {
        if (this.multiple && this.limit > 1) {
          this.files.splice(index, 1)
          this.$emit('change', this.files)
        } else {
          this.files = []
          this.$emit('change', "")
        }
      },
      handleChoose(files) {
        if (this.limit !== 1) {
          files.forEach(v => {
            this.files.push(v.url)
          })
          this.$emit('change', this.files)
        } else {
          this.files = [files[0].url]
          this.$emit('change', files[0].url)
        }
      },
      handleDrag(files) {
        if (this.draggable) {
          this.files = files
          this.$emit('change', files)
        }
      }
    },
    components: {
      ImageList,
      FilePicker,
      VideoList,
    }
  }
</script>

<style lang="scss">
  .mm {
    &-handle-upload {
      float: left;
      .item {

        border-radius: $box-radius;
        border: 1px dashed $main-border-color;
        text-align: center;
        cursor: pointer;
        color: $remark-text-color;
        display: inline-block;
        position: relative;

        &:hover {
          border-color: $theme-color;
          color: $theme-color;
        }

        .upload {
          display: inline-block;
          text-align: center;
          margin-top: 50%;
          transform: translateY(-28px);

          &-icon {
            display: inline-block;
            font-size: 30px;
            line-height: 1;
          }

          &-text {
            display: block;
            line-height: 1;
          }
        }
      }
    }
  }

  .mm:after {
    clear: both;
    display: block;
    content: " ";
  }
</style>
