export default {
  union: {
    url: '/v1/mkt/user/union',
    auth: true,
    method: 'GET',
  },
  list: {
    url: '/v1/mkt/user/list',
    auth: true,
    method: 'GET',
  },
  detail: {
    url: '/v1/mkt/user/detail',
    auth: true,
    method: 'GET',
  },

}
